<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        :to="{
          path: '/academic_Management/academic_font_management/academic_font_management_home',
        }"
        >学术系统</el-breadcrumb-item
      >
      <el-breadcrumb-item>试卷管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 题库信息筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="组别" prop="level">
              <el-select
                v-model="queryForm.level"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_Group"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择类型"
                style="width: 100%"
                v-model="queryForm.season"
                clearable
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="试卷名称" prop="name">
              <el-input
                v-model="queryForm.name"
                placeholder="请输入名称"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="试卷类型" prop="type">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                clearable
                v-model="queryForm.type"
              >
                <el-option
                  v-for="item in dict_question_type"
                  :key="item.id"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="set" prop="set">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.set"
                clearable
              >
                <el-option
                  v-for="item in dict_set"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赛区" prop="districtId">
              <el-select
                placeholder="请选择"
                v-model="queryForm.districtId"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_district"
                  :key="item.id"
                  :label="item.districtName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属赛程" prop="raceSchedule">
              <el-select
                v-model="queryForm.raceSchedule"
                placeholder="请选择赛程"
                @visible-change="changeRaceScheduleValue"
                clearable
                style="width: 100%"
                ><el-option
                  v-for="item in raceScheduleList"
                  :key="item.scheduleSort"
                  :label="item.scheduleName"
                  :value="Number(item.id)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否启用" prop="status">
              <el-select
                v-model="queryForm.status"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_examstatus"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getExamListData('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="5">
          <span class="title-class title_class">试卷列表</span>
        </el-col>
        <el-col :span="19" style="text-align: right">
          <el-button type="primary" @click="addExamDataClicked"
            >新增试卷+</el-button
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="examListData"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="试卷名称" prop="name"></el-table-column>
        <el-table-column label="赛季" prop="season"></el-table-column>
        <el-table-column
          label="赛区"
          prop="districtId"
          :formatter="districtFormatter"
        ></el-table-column>
        <!-- <el-table-column label="试卷名称" prop="name"></el-table-column> -->
        <el-table-column label="组别" prop="level">
          <template slot-scope="scope">
            <span v-if="scope.row.level === 0">小初组</span>
            <span v-else-if="scope.row.level === 1">小中组</span>
            <span v-else-if="scope.row.level === 2">小高组</span>
            <span v-else-if="scope.row.level === 3">初中组</span>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column
          label="set"
          prop="set"
          :formatter="setsFormatter"
        ></el-table-column>
        <el-table-column label="是否启用" prop="status">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == true"
              >已启用</el-tag
            >
            <el-tag type="danger" v-else>未启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="IBC届" prop="ibcSession" :formatter="ibcSessionFormatter" v-if="queryForm.type === 'ibc'"></el-table-column>
        <el-table-column label="IBC季度" prop="ibcSeason" :formatter="ibcSeasonFormatter" v-if="queryForm.type === 'ibc'"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="getExamDetailData(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-setting"
              size="mini"
              :style="{color: getStateColor(scope.row.status)}"
              @click="turnToSetExamDetail(scope.row.id)"
              >设置</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              :style="{color: getStateColor(scope.row.status)}"
              :disabled="scope.row.status"
              @click="deleteDialog(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑试卷弹窗 -->
    <el-dialog
      :title="isEdit == true ? '编辑试卷' : '新增试卷'"
      :visible.sync="addExamDataDialog"
      width="50%"
      @close="editExamDialogClose"
    >
      <el-form
        :model="addInfoForm"
        label-width="100px"
        ref="addInfoFormRef"
        :rules="addInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="试卷类型" prop="type">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                @change="typeChanged"
                v-model="addInfoForm.type"
              >
                <el-option
                  v-for="item in dict_question_type"
                  :key="item.id"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="addInfoForm.type === 'ibc'">
            <el-form-item label="届" prop="ibcSession">
              <el-select
                placeholder="请选择类型"
                style="width: 100%"
                v-model="addInfoForm.ibcSession"
              >
                <el-option
                  v-for="item in dict_ibcSession"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"  v-if="addInfoForm.type !== 'ibc'">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择类型"
                style="width: 100%"
                v-model="addInfoForm.season"
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="试卷名称" prop="name">
              <el-input v-model="addInfoForm.name" placeholder="请输入名称">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第几套" prop="set">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="addInfoForm.set"
              >
                <el-option
                  v-for="item in dict_set"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="赛区" prop="districtId">
              <el-select
                placeholder="请选择"
                v-model="addInfoForm.districtId"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_district"
                  :key="item.id"
                  :label="item.districtName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="addInfoForm.type === 'ibc'">
            <el-form-item label="IBC季度" prop="ibcSeason">
              <el-select
                @visible-change="addchangeRaceScheduleValue"
                v-model="addInfoForm.ibcSeason"
                placeholder="请选择赛程"
                clearable
                style="width: 100%"
                ><el-option
                  v-for="item in dict_ibcSeason"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="所属赛程" prop="raceSchedule">
              <el-select
                @visible-change="addchangeRaceScheduleValue"
                v-model="addInfoForm.raceSchedule"
                placeholder="请选择赛程"
                clearable
                style="width: 100%"
                ><el-option
                  v-for="item in raceScheduleList"
                  :key="item.scheduleSort"
                  :label="item.scheduleName"
                  :value="Number(item.id)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组别" prop="level">
              <el-select
                v-model="addInfoForm.level"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_Group"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否启用" prop="status">
              <el-select
                v-model="addInfoForm.status"
                placeholder="请选择"
                style="width: 100%"
                clearable
              >
                <el-option
                  v-for="item in dict_examstatus"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addExamDataDialog = false">取 消</el-button>
        <el-button type="primary" @click="addExamInfoClicked">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getQuestionPaper, getExamDetail, deleteExamData, saveChangeExamtional, reatedExamtional, queryDistrictPage } from '@/http/api'
export default {
  data () {
    return {
      isEdit: false,
      addExamDataDialog: false,
      addInfoForm: {
        season: this.$chnEngStatusCode.defaultSeason,
        districtId: undefined,
        type: null,
        name: '',
        level: null,
        status: null
      },
      examListData: [],
      total: 0,
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      dict_district: [],
      addInfoRules: {
        type: [{ required: true, message: '请选择该试卷set值', trigger: 'change' }],
        name: [{ required: true, message: '请输入该试卷名称', trigger: 'blur' }],
        set: [{ required: true, message: '请选择该试卷set值', trigger: 'change' }],
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        raceSchedule: [{ required: true, message: '请选择', trigger: 'change' }],
        level: [{ required: true, message: '请选择', trigger: 'change' }],
        status: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcSession: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcSeason: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      dict_examstatus: this.$userInfo.dictExamstatus(),
      dict_Group: this.$userInfo.dict_group(),
      dict_set: this.$userInfo.dictSet(),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dict_ibcSeason: this.$userInfo.getDataList(this, 'ibc_season').then(value => { this.dict_ibcSeason = value }),
      dict_question_type: this.$userInfo.getDataList(this, 'question_paper_type').then(value => { this.dict_question_type = value }),
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value })
    }
  },
  created () {
    this.raceScheduleList = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.raceScheduleList = value })
    this.getExamListData()
    this.getDistictData()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    getDistictData () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getExamListData (type) {
      this.addExamDataDialog = false
      if (type === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getQuestionPaper(this.queryForm).then((res) => {
        this.examListData = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getExamListData('search')
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getExamListData()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getExamListData()
    },
    editExamDialogClose () {
      this.isEdit = false
      this.addInfoForm = {
        season: this.$chnEngStatusCode.defaultSeason,
        districtId: undefined,
        type: null,
        name: '',
        level: null,
        status: null
      }
      this.$refs.addInfoFormRef.resetFields()
    },
    addchangeRaceScheduleValue (state) {
      console.log(state)
      if (state === true && (this.addInfoForm.type === null || this.addInfoForm.type === '')) {
        return this.$message.error('请先选择试卷类型')
      }
    },
    changeRaceScheduleValue (state) {
      console.log(state)
      if (state === true && (this.queryForm.type === null || this.queryForm.type === '')) {
        return this.$message.error('请先选择试卷类型')
      }
    },
    typeChanged () {
      if (this.addInfoForm.type === 'ibc') {
        this.addInfoForm.ibcSeason = ''
        this.addInfoForm.ibcSession = ''
      }
      console.log('this.addInfoForm', this.addInfoForm)
    },
    // 添加试卷确定点击事件
    addExamInfoClicked () {
      if (this.addInfoForm.type === 'ibc') {
        delete this.addInfoForm.raceSchedule
      }
      if (this.isEdit === true) {
        saveChangeExamtional(this.addInfoForm).then((res) => {
          this.addQuestionDataDialog = false
          this.$message.success('保存成功')
          this.getExamListData()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        reatedExamtional(this.addInfoForm).then((res) => {
          this.addQuestionDataDialog = false
          this.$message.success('保存成功')
          this.getExamListData()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    addExamDataClicked () {
      this.addExamDataDialog = true
    },
    // 编辑试卷时获取试卷详情
    getExamDetailData (rowId) {
      getExamDetail(rowId).then((res) => {
        this.addInfoForm = res.data
        this.addInfoForm.level = res.data.level
        this.isEdit = true
        this.addExamDataDialog = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 设置试卷
    turnToSetExamDetail (rowId) {
      this.$router.push(
        {
          path: '/academic_Management/academic_font_management/academic_set_examination',
          query: {
            examId: rowId
          }
        }
      )
    },
    // 删除点击事件
    deleteDialog (rowId) {
      this.$confirm('是否确认删除该试卷?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteExamData(rowId).then((res) => {
          this.$message.success('删除成功')
          this.getExamListData()
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => { })
    },
    getStateColor (state) {
      switch (state) {
        case true:
          return '#999999'
        case false:
          return '#f56c6c'
        default:
          break
      }
    },
    districtFormatter (data) {
      var tempName = '未设置'
      this.dict_district.forEach(element => {
        if (element.id === data.districtId) {
          tempName = element.districtName
        }
      })
      return tempName
    },
    setsFormatter (data) {
      var tempName = ''
      this.dict_set.forEach(element => {
        if (element.dictValue === data.set) {
          tempName = element.dictLabel
        }
      })
      return tempName
    },
    ibcSessionFormatter (data) {
      var tempName = ''
      if (data.ibcSession) {
        this.dict_ibcSession.forEach(element => {
          if (element.dictValue === data.ibcSession + '') {
            tempName = element.dictLabel
          }
        })
      }
      return tempName
    },
    ibcSeasonFormatter (data) {
      var tempName = ''
      if (data.ibcSeason) {
        this.dict_ibcSeason.forEach(element => {
          if (element.dictValue === data.ibcSeason) {
            tempName = element.dictLabel
          }
        })
      }
      return tempName
    }
  }
}
</script>
<style>
</style>
